<script setup lang="ts">
const open = defineModel<boolean>('open')

const { meta_k } = useMagicKeys()
const route = useRoute()
const router = useRouter()
const workspace = useWorkspace()

const commandInput = ref('')

const claimIdMatch = computed(() => {
  const claimId = commandInput.value.trim()
  if (!claimId) {
    return ''
  }

  if (claimId.startsWith('Claim')) {
    return claimId
  }

  return claimId.match(/\d+/) && claimId.trim().length >= 9 ? claimId : ''
})

const isPolicyId = computed(() => {
  return commandInput.value.match(/^policy_\d+/)
})

const { data, status, refresh } = useApiFetch<{ id: string; name: string }[]>(
  () =>
    `/api/workspace/${workspace.workspaceId}/find-claim-connectors/${claimIdMatch.value}`,
  { default: () => [], immediate: false, server: false, watch: false },
)

const policiesSearchQuery = computed(() => {
  return {
    connectors: workspace.connectors.map((connector) => connector.id),
    q: commandInput.value,
    policy_id: isPolicyId.value ? commandInput.value : undefined,
  }
})

const {
  data: policies,
  status: policiesStatus,
  refresh: searchPolicies,
} = useApiFetch<{ id: string; name: string }[]>(
  () =>
    `/api/workspace/${workspace.workspaceId}/find-policies/${claimIdMatch.value}`,
  {
    body: policiesSearchQuery,
    method: 'POST',
    default: () => [],
    immediate: false,
    server: false,
    watch: false,
    transform: (data) => {
      const connectors = workspace.connectors.reduce<Record<string, string>>(
        (acc, connector) => {
          acc[connector.id] = connector.name
          return acc
        },
        {},
      )
      return data.map((item) => ({
        ...item,
        connector_name: connectors[item.connector_id],
      }))
    },
  },
)

const filteredCommands = ['create', 'go']

debouncedWatch(
  [commandInput, isPolicyId, claimIdMatch],
  ([input, newIsPolicyId, newClaimIdMatch]) => {
    if (!input) {
      policies.value = []
      return
    }

    if (newClaimIdMatch) {
      policies.value = []
      return refresh()
    }

    if (input.length <= 3) {
      return
    }

    if (
      filteredCommands.some((command) =>
        input.toLowerCase().startsWith(command),
      )
    ) {
      return
    }

    if (newIsPolicyId) {
      return searchPolicies()
    }

    return searchPolicies()
  },
  {
    debounce: 600,
  },
)

const connectors = useSimpleSearch(
  computed(() => workspace.connectors),
  commandInput,
  'name',
)

const pages = computed(() => [
  {
    label: 'Go to dashboard',
    name: 'index',
    to: '/',
  },
  {
    label: 'Go to policies',
    name: 'policies',
    to: '/policies',
  },
  {
    label: 'Go to claims',
    name: 'claims',
    to: '/claims',
  },
  {
    label: 'Go to exclusions',
    name: 'exclusions',
    to: '/exclusions',
  },
  ...(workspace.canManageConnector
    ? [
        {
          label: 'Create Exclusion',
          name: 'exclusions-create',
          to: '/exclusions/create',
        },
      ]
    : []),
  {
    label: 'Go to changelog',
    name: 'changelog',
    to: '/changelog',
  },
])

const pageList = useSimpleSearch(pages, commandInput, 'label')

const navToClaim = async (connectorId: string) => {
  // this work around is needed because of the router.push('/claims') on the claim id page
  // https://gitlab.com/rialtic/workflows/console-ui/-/blob/235d8e0c9a31fc9fe883b0e4aceac181c1905db2/apps/console/pages/claims/%5Bid%5D.vue#L137
  if (route.name === 'claims-id') {
    await router.push('/claims')
  }
  if (workspace.connectorId !== connectorId) {
    workspace.setActiveConnector(connectorId)
  }
  router.push({
    name: 'claims-id',
    params: {
      id: claimIdMatch.value,
    },
  })
}

const navToPolicy = async (policyId: string, connectorId: string) => {
  if (route.name === 'policies-id') {
    await router.push('/policies')
  }
  if (workspace.connectorId !== connectorId) {
    workspace.setActiveConnector(connectorId)
  }
  await router.push({
    name: 'policies-id',
    params: {
      id: policyId,
    },
  })
}

const navToPage = async (page: { to: string }) => {
  await router.push(page.to)
  open.value = false
}

const switchConnector = async (connectorId: string) => {
  if (workspace.connectorId !== connectorId) {
    workspace.setActiveConnector(connectorId)
  }
  open.value = false
}

const onEnterKey = () => {
  if (!open.value || !commandInput.value) {
    return
  }

  if (claimIdMatch.value && data.value?.length) {
    return navToClaim(data.value[0].id)
  }

  if (isPolicyId.value) {
    return navToPage({
      to: `/policies/${commandInput.value}`,
    })
  }

  if (connectors.value.length) {
    return switchConnector(connectors.value[0].id)
  }

  if (pageList.value.length) {
    return navToPage(pageList.value[0])
  }
}

whenever(meta_k, () => {
  open.value = !open.value
})

whenever(
  () => !open.value,
  () => {
    commandInput.value = ''
  },
)
</script>

<template>
  <UiDialog v-model="open" max-width="520px">
    <div class="card">
      <header
        class="bg-surface sticky top-0 z-1 flex items-center justify-between border-b px-2 py-1"
      >
        <i
          class="i-ph-magnifying-glass pointer-events-none absolute inline-block h-5 w-5 text-neutral-700"
        />
        <input
          v-model="commandInput"
          class="w-full flex-1 px-7 py-2 text-sm text-xs leading-4 text-neutral-900 outline-none"
          autofocus
          placeholder="Search..."
          @keydown.enter="onEnterKey"
        />
        <UiBtnClose @click="open = false" />
      </header>

      <ul v-if="claimIdMatch" class="p-1">
        <li
          v-if="status === 'pending'"
          class="flex items-center justify-center py-1 text-sm text-neutral-800"
        >
          <UiLoading class="text-secondary mr-2 h-5 w-5" />
          Searching for claim across all lines of business
        </li>

        <li v-for="(connector, index) in data" :key="connector.id">
          <UiListItemAction @click="navToClaim(connector.id)">
            <span class="flex items-center justify-between">
              {{ connector.name }}

              <i
                v-if="index === 0"
                class="i-ph-key-return-bold inline-block h-4 w-4 text-neutral-800"
              />
            </span>
          </UiListItemAction>
        </li>
      </ul>
      <template v-if="commandInput.length">
        <ul v-if="policiesStatus === 'pending'" class="p-1">
          <li
            class="flex items-center justify-center py-1 text-sm text-neutral-800"
          >
            <UiLoading class="text-secondary mr-2 h-5 w-5" />
            Searching for policies across all lines of business
          </li>
        </ul>

        <ul v-if="pageList.length" class="p-1">
          <li class="subtitle-1 px-2 text-neutral-800">Quick links</li>

          <li v-for="(page, index) in pageList" :key="page.to">
            <UiListItemTick
              :checked="page.name === route.name"
              @click="navToPage(page)"
            >
              <span class="flex items-center justify-between">
                {{ page.label }}

                <i
                  v-if="
                    index === 0 &&
                    !((claimIdMatch && data?.length) || connectors.length)
                  "
                  class="i-ph-key-return-bold inline-block h-4 w-4 text-neutral-800"
                />
              </span>
            </UiListItemTick>
          </li>
        </ul>

        <ul v-if="policies.length" class="p-1">
          <li class="subtitle-1 px-2 text-neutral-800">Policies</li>

          <li v-for="policy in policies" :key="policy.id">
            <UiListItemAction
              @click="navToPolicy(policy.id, policy.connector_id)"
            >
              <div class="flex items-center justify-between">
                <div class="overflow-hidden pr-4">
                  <p class="truncate">{{ policy.name }}</p>
                  <p class="text-xs text-neutral-700">
                    {{ policy.connector_name }}
                  </p>
                </div>

                <UiLabel
                  :color="
                    policy.insight_mode === 'Active'
                      ? 'primary'
                      : policy.insight_mode === 'Observation'
                      ? 'tertiary'
                      : undefined
                  "
                >
                  {{ policy.insight_mode }}
                </UiLabel>
              </div>
            </UiListItemAction>
          </li>
        </ul>

        <ul v-if="connectors.length" class="p-1">
          <li class="subtitle-1 px-2 text-neutral-800">Connectors</li>

          <li v-for="(connector, index) in connectors" :key="connector.id">
            <UiListItemTick
              :checked="connector.id === workspace.connectorId"
              @click="switchConnector(connector.id)"
            >
              <span class="flex items-center justify-between">
                {{ connector.name }}

                <i
                  v-if="index === 0 && !(claimIdMatch && data?.length)"
                  class="i-ph-key-return-bold inline-block h-4 w-4 text-neutral-800"
                />
              </span>
            </UiListItemTick>
          </li>
        </ul>
      </template>
      <footer class="p-2">
        <p class="text-xs text-neutral-700">
          Search for claims, policies, lines of business, and more
        </p>
      </footer>
    </div>
  </UiDialog>
</template>
